const __pages_import_0__ = () => import("/src/pages/rst.vue");
const __pages_import_1__ = () => import("/src/pages/plan.vue");
const __pages_import_2__ = () => import("/src/pages/nutzer.vue");
const __pages_import_3__ = () => import("/src/pages/nnf.vue");
const __pages_import_4__ = () => import("/src/pages/karte.vue");
import __pages_import_5__ from "/src/pages/index.vue";
const __pages_import_6__ = () => import("/src/pages/entfernung.vue");
const __pages_import_7__ = () => import("/src/pages/apotheke.vue");
const __pages_import_8__ = () => import("/src/pages/aenderungsantrag.vue");
const __pages_import_9__ = () => import("/src/pages/admin.vue");
const __pages_import_10__ = () => import("/src/pages/[...all].vue");
const __pages_import_11__ = () => import("/src/pages/detail/apotheke/[id].vue");
const __pages_import_12__ = () => import("/src/pages/detail/nnf/[id].vue");
const __pages_import_13__ = () => import("/src/pages/detail/nutzer/[id].vue");
const __pages_import_14__ = () => import("/src/pages/detail/plan/[id].vue");
const __pages_import_15__ = () => import("/src/pages/detail/rst/[id].vue");

const routes = [{"name":"rst","path":"/rst","component":__pages_import_0__,"props":true,"meta":{"name":"Rezeptsammelstellen"}},{"name":"plan","path":"/plan","component":__pages_import_1__,"props":true,"meta":{"name":"Pläne"}},{"name":"nutzer","path":"/nutzer","component":__pages_import_2__,"props":true,"meta":{"name":"Nutzer"}},{"name":"nnf","path":"/nnf","component":__pages_import_3__,"props":true,"meta":{"name":"NNF-Abrechnung"}},{"name":"karte","path":"/karte","component":__pages_import_4__,"props":true,"meta":{"name":"Karte"}},{"name":"index","path":"/","component":__pages_import_5__,"props":true,"meta":{"name":"Index"}},{"name":"entfernung","path":"/entfernung","component":__pages_import_6__,"props":true,"meta":{"name":"Entfernungen"}},{"name":"apotheke","path":"/apotheke","component":__pages_import_7__,"props":true,"meta":{"name":"Apotheken"}},{"name":"aenderungsantrag","path":"/aenderungsantrag","component":__pages_import_8__,"props":true,"meta":{"name":"Anträge"}},{"name":"admin","path":"/admin","component":__pages_import_9__,"props":true,"meta":{"name":"Admin"}},{"name":"all","path":"/:all(.*)*","component":__pages_import_10__,"props":true,"meta":{"layout":404}},{"name":"detail-apotheke-id","path":"/detail/apotheke/:id","component":__pages_import_11__,"props":true,"meta":{"name":"Apotheke"}},{"name":"detail-nnf-id","path":"/detail/nnf/:id","component":__pages_import_12__,"props":true,"meta":{"name":"Apotheke"}},{"name":"detail-nutzer-id","path":"/detail/nutzer/:id","component":__pages_import_13__,"props":true,"meta":{"name":"Nutzer"}},{"name":"detail-plan-id","path":"/detail/plan/:id","component":__pages_import_14__,"props":true,"meta":{"name":"Plan"}},{"name":"detail-rst-id","path":"/detail/rst/:id","component":__pages_import_15__,"props":true,"meta":{"name":"Rezeptsammelstelle"}}];

export default routes;