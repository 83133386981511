<script setup lang="ts">
const userStore = useUserStore()
</script>

<template>
  <aside id="default-sidebar" class="fixed left-0 top-0 z-40 hidden h-full w-64 pt-15 font-normal transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidenav">
    <div class="relative h-full min-h-0 flex flex-1 flex-col border-r border-gray-200 bg-white pt-0 dark:border-gray-700 dark:bg-gray-800">
      <div class="flex flex-1 flex-col overflow-y-auto pb-4 pt-5">
        <div class="flex-1 bg-white px-3 space-y-1 divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
          <ul class="border-b border-gray-200 pb-2 space-y-2 dark:border-gray-700">
            <li>
              <TheSidebarLink label="Dashboard" route="index" icon="mdi-view-dashboard" />
            </li>
            <li v-if="userStore.canNutzer">
              <TheSidebarLink label="Nutzer" route="nutzer" icon="mdi-account-group" />
            </li>
            <li v-if="userStore.canApotheken">
              <TheSidebarLink label="Apotheken" route="apotheke" icon="mdi-domain" />
            </li>
            <li v-if="userStore.canRst">
              <TheSidebarLink label="Rezeptsammelstellen" route="rst" icon="mdi-home-group" />
            </li>
            <li v-if="userStore.canPlaene">
              <TheSidebarLink label="Pläne" route="plan" icon="mdi-calendar-multiselect" />
            </li>
            <li v-if="userStore.canAntraege">
              <TheSidebarLink label="Änderungsanträge" route="aenderungsantrag" icon="mdi-calendar-sync" />
            </li>
            <li v-if="userStore.canNNF">
              <TheSidebarLink label="NNF-Abrechnung" route="nnf" icon="mdi-abacus" />
            </li>
            <li v-if="userStore.canKarte">
              <TheSidebarLink label="Karte" route="karte" icon="mdi-map" />
            </li>
            <li v-if="userStore.canEntfernungen">
              <TheSidebarLink label="Entfernungen" route="entfernung" icon="mdi-map-marker-distance" />
            </li>
            <li v-if="userStore.canAdmin">
              <TheSidebarLink label="Admin" route="admin" icon="mdi-shield-crown" />
            </li>
          </ul>
        </div>
      </div>

      <div class="absolute bottom-0 left-0 hidden w-full justify-center bg-white p-4 lg:flex space-x-4 dark:bg-gray-800" sidebar-bottom-menu>
        <p class="text-center text-sm text-gray-500">
          &copy; <a href="https://www.sberg.net/" class="hover:underline" target="_blank">sberg it systeme GmbH</a>
        </p>
      </div>
    </div>
  </aside>

  <div id="sidebarBackdrop" class="fixed inset-0 z-20 hidden bg-gray-900/50 opacity-50 dark:bg-gray-900/90" />
</template>
