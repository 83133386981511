import type { UserModule } from '~/types'
import * as typeFile from '~/types'

function formatEnum(value: string, type: string): string {
  if (!value || !type)
    return '-'

  const importedType = (typeFile as any)[type]
  const typeValue = importedType[value]
  return typeValue || `FEHLER: ${value}`
}

export const install: UserModule = ({ app }) => {
  app.config.globalProperties.$filters = {
    formatNutzer(id: number | undefined): string {
      const userStore = useUserStore()

      if (!id || id === 0)
        return '-'
      if (!userStore.reducedUserList)
        return '-'
      const user = userStore.reducedUserList.find(user => user.id === id)
      return user?.nutzername || 'Nutzer im Cache nicht gefunden'
    },
    formatNutzerFromValues(id: number | undefined, values?: Array<any>): string {
      if (!id || id === 0)
        return '-'
      if (!values)
        return '-'
      const user = values.find(user => user.id === id)
      return user?.nutzername || `Fehler (${id})`
    },
    formatEnum,
  }

  app.provide('$formatEnum', formatEnum)
}

interface Filters {
  formatNutzer(id: number | undefined): string
  formatNutzerFromValues(id: number | undefined, values?: Array<any>): string
  formatEnum(value: any, type: any): string
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $filters: Filters
  }
}
