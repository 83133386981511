<script setup lang="ts">
import __MACROS_useVModel from "/vue-macros/define-models/use-vmodel";
defineProps({
  id: null,
  modelValue: null,
  type: null,
  label: null,
  placeholder: null,
  disabled: { type: Boolean },
  options: null,
  valuekey: null,
  idkey: null,
  help: null,
  v$: null,
  required: { type: Boolean },
  clearable: { type: Boolean },
  typename: null,
  returnObject: { type: Boolean },
  min: null,
  max: null,
  getValue: { type: Function },
  bindAttrs: { type: Boolean },
  accept: null,
  rows: null,
  invalid: { type: Boolean }
});
import type { Validation } from '@vuelidate/core'
import { useTextareaAutosize } from '@vueuse/core'

import * as typeFile from '~/types'

const emit = defineEmits(["update:modelValue", "update:id", "update:type", "update:label", "update:placeholder", "update:disabled", "update:options", "update:valuekey", "update:idkey", "update:help", "update:v$", "update:required", "update:clearable", "update:typename", "update:returnObject", "update:min", "update:max", "update:getValue", "update:bindAttrs", "update:accept", "update:rows", "update:invalid"])

const { id, modelValue, type, disabled, options, valuekey, idkey, typename, returnObject, min } = __MACROS_useVModel("id", "modelValue", "type", "label", "placeholder", "disabled", "options", "valuekey", "idkey", "help", "v$", "required", "clearable", "typename", "returnObject", "min", "max", "getValue", "bindAttrs", "accept", "rows", "invalid")

const { textarea, input } = useTextareaAutosize({ styleProp: 'minHeight' })
// input.value = modelValue.value

const showPassword = ref(false)

const isDisabled = computed(() => {
  if (disabled.value)
    return disabled.value
  return false
})

function handleChange(event: Event) {
  const { target } = event
  if (target)
    return (target as HTMLInputElement).value
  return null
}
function handleChangeCheckbox(event: Event) {
  const { target } = event
  if (target)
    return (target as HTMLInputElement).checked
  return null
}
function handleChangeSelect(event: Event) {
  const { target } = event
  if (target) {
    const selectedIndex = (target as HTMLSelectElement).selectedIndex
    const optionsHtml = (target as any).querySelectorAll('option')

    if (selectedIndex <= 0)
      return

    const value = optionsHtml[selectedIndex]._value

    if (options.value && returnObject.value) {
      const mappedElements = Array.isArray(options.value)
        ? options.value.map((x: any) => x[idkey.value ? idkey.value : 'id'])
        : Object.values(options.value).map((x: any) => x[idkey.value ? idkey.value : 'id'])
      const elementPos = mappedElements.indexOf(Number(value))
      return Array.isArray(options.value) ? options.value[elementPos] : Object.values(options.value)[elementPos]
    }

    if (valuekey.value && options.value) {
      const mappedElements = Array.isArray(options.value)
        ? options.value.map((x: any) => x[idkey.value ? idkey.value : 'id'])
        : Object.values(options.value).map((x: any) => x[idkey.value ? idkey.value : 'id'])
      const elementPos = mappedElements.indexOf(Number(value))
      return options.value[elementPos]
    }
    return value
  }
  return null
}

function formatEnum(value: string, type: string): string {
  if (!value || !type)
    return `FEHLER: ${value}`

  const importedType = (typeFile as any)[type]
  const typeValue = importedType[value]
  return typeValue || `FEHLER: ${value}`
}

function onUploadFiles(event: Event) {
  emit('update:modelValue', (event.target as any).files[0])
}

/*
watch(modelValue, async (newValue, oldValue) => {
  if (type.value === 'time') {
    const timeControl: HTMLElement | null = document.getElementById(id.value)
    if (timeControl)
      nextTick(() => (timeControl as HTMLInputElement).value = newValue)
  }
})
*/
</script>

<template>
  <div class="mb-2">
    <label
      :for="id"
      :class="((v$ && v$[id] && v$[id].$errors[0]) || invalid) ? 'sb-input-label-error' : 'sb-input-label'"
      @click.exact.stop.prevent=""
    >
      {{ label }}<span v-if="required" class="text-red">*</span>
    </label>

    <template v-if="type === 'email'">
      <div class="flex">
        <span class="inline-flex items-center border border-r-0 border-gray-300 rounded-l-md bg-gray-200 px-3 text-sm text-gray-900 dark:border-gray-600 dark:bg-gray-600 dark:text-gray-400">
          <div class="pointer-events-none h-5 w-5 text-gray-500 dark:text-gray-400" i-mdi-email />
        </span>
        <input
          :id="id"
          :value="modelValue"
          :type="type"
          :placeholder="placeholder"
          :class="((v$ && v$[id] && v$[id].$errors[0]) || invalid) ? 'rounded-none rounded-r-md sb-input-error' : 'rounded-none rounded-r-md sb-input'"
          :disabled="isDisabled"
          @input="$emit('update:modelValue', handleChange($event))"
        >
      </div>
    </template>

    <template v-else-if="type === 'checkbox'">
      <div class="flex grid-items-center p-2">
        <input
          :id="id"
          :checked="modelValue"
          type="checkbox"
          :placeholder="placeholder"
          :class="((v$ && v$[id] && v$[id].$errors[0]) || invalid) ? 'sb-input-error-check' : 'sb-input-check'"
          :disabled="isDisabled"
          @change="$emit('update:modelValue', handleChangeCheckbox($event))"
        >
      </div>
    </template>

    <template v-else-if="type === 'select' && options">
      <select
        :id="id"
        :value="idkey ? modelValue ? modelValue[idkey] : undefined : modelValue"
        :placeholder="placeholder"
        :class="((v$ && v$[id] && v$[id].$errors[0]) || invalid) ? 'sb-input-error' : 'sb-input-dropdown'"
        :disabled="isDisabled"
        @change="$emit('update:modelValue', handleChangeSelect($event))"
      >
        <option value="-1" disabled selected>
          Bitte auswählen
        </option>
        <option
          v-for="option in options"
          :key="option"
          :value="idkey ? option[idkey] : option"
        >
          <template v-if="typename">
            {{ valuekey ? formatEnum(option[valuekey], typename) : formatEnum(option, typename) }}
          </template>
          <template v-else-if="getValue">
            {{ getValue(option) }}
          </template>
          <template v-else>
            {{ valuekey ? option[valuekey] : option }}
          </template>
        </option>
      </select>
    </template>

    <template v-else-if="type === 'time'">
      <input
        :id="id"
        :value="modelValue"
        :type="type"
        :placeholder="placeholder"
        :class="((v$ && v$[id] && v$[id].$error) || invalid) ? 'sb-input-error' : 'sb-input'"
        :disabled="isDisabled"
        @input="$emit('update:modelValue', handleChange($event))"
      >
      <div v-if="help" id="helper-text-explanation" class="text-sm text-gray-500 dark:text-gray-400" v-html="help" />
      <p
        v-if="((v$ && v$[id] && v$[id].$errors[0]) || invalid)"
        class="sb-input-p-error"
      >
        <span v-if="invalid" class="font-medium">Dies ist ein Pflichtfeld</span>
        <span v-else class="font-medium">{{ v$[id].$errors[0].$message }}</span>
      </p>
    </template>

    <template v-else-if="type === 'textarea'">
      <textarea
        :id="id"
        ref="textarea"
        class="h-fill resize-none overflow-y-hidden"
        :value="modelValue"
        :type="type"
        :placeholder="placeholder"
        :class="((v$ && v$[id] && v$[id].$error) || invalid) ? 'sb-input-error' : 'sb-input'"
        :disabled="isDisabled"
        :rows="rows ? rows : 1"
        @input="($event) => {
          $emit('update:modelValue', handleChange($event))
          input = handleChange($event) || ''
        }"
      />
      <!-- div v-if="help" id="helper-text-explanation" class="text-sm text-gray-500 dark:text-gray-400" v-html="help" / -->
      <p
        v-if="((v$ && v$[id] && v$[id].$errors[0]) || invalid)"
        class="sb-input-p-error"
      >
        <span v-if="invalid" class="font-medium">Dies ist ein Pflichtfeld</span>
        <span class="font-medium">{{ v$[id].$errors[0].$message }}</span>
      </p>
    </template>

    <template v-else-if="type === 'password'">
      <div class="relative">
        <input
          :id="id"
          :value="modelValue"
          :type="showPassword ? 'text' : 'password'"
          :placeholder="placeholder"
          :class="((v$ && v$[id] && v$[id].$error) || invalid) ? 'sb-input-error' : 'sb-input'"
          :disabled="isDisabled"
          :min="min"
          v-bind="$attrs"
          @change="$emit('update:modelValue', handleChange($event))"
        >

        <button
          class="absolute right-2 top-1/2 transform rounded-lg px-4 py-2 text-sm text-black font-medium -translate-y-1/2 hover:bg-gray-700 dark:text-white focus:outline-none dark:hover:bg-gray-300"
          :class="showPassword ? 'i-mdi-eye-outline' : 'i-mdi-eye-off-outline'"
          @click.exact.stop.prevent="showPassword = !showPassword"
        />
      </div>
    </template>

    <template v-else-if="type === 'number'">
      <div class="relative">
        <input
          :id="id"
          :value="modelValue"
          type="number"
          :placeholder="placeholder"
          :class="((v$ && v$[id] && v$[id].$error) || invalid) ? 'sb-input-error' : 'sb-input'"
          :disabled="isDisabled"
          @input="$emit('update:modelValue', handleChange($event))"
        >
      </div>
    </template>

    <template v-else-if="type === 'file'">
      <div class="relative">
        <input
          :id="id"
          type="file"
          :class="((v$ && v$[id] && v$[id].$error) || invalid) ? 'sb-input-error' : 'sb-input'"
          :disabled="isDisabled"
          :accept="accept"
          @change="onUploadFiles"
        >
      </div>
    </template>

    <div v-else class="relative">
      <input
        v-if="bindAttrs"
        :id="id"
        :value="modelValue"
        :type="type"
        :placeholder="placeholder"
        :class="((v$ && v$[id] && v$[id].$error) || invalid) ? 'sb-input-error' : 'sb-input'"
        :disabled="isDisabled"
        :min="min"
        v-bind="$attrs"
        @change="$emit('update:modelValue', handleChange($event))"
      >

      <input
        v-else
        :id="id"
        :value="modelValue"
        :type="type"
        :placeholder="placeholder"
        :class="((v$ && v$[id] && v$[id].$error) || invalid) ? 'sb-input-error' : 'sb-input'"
        :disabled="isDisabled"
        :min="min"
        :max="max"
        @change="$emit('update:modelValue', handleChange($event))"
      >

      <button
        v-if="clearable"
        class="absolute bottom-2 right-2 rounded-lg px-4 py-2 text-sm text-black font-medium hover:bg-gray-700 dark:text-white focus:outline-none dark:hover:bg-gray-300"
        i-mdi-close-circle-outline
        @click.exact.stop.prevent="$emit('update:modelValue', undefined)"
      />
    </div>

    <div v-if="help" id="helper-text-explanation" class="text-sm text-gray-500 dark:text-gray-400" v-html="help" />

    <p
      v-if="((v$ && v$[id] && v$[id].$errors[0]) || invalid)"
      class="sb-input-p-error"
    >
      <span v-if="invalid" class="font-medium">Dies ist ein Pflichtfeld</span>
      <span v-else class="font-medium">{{ v$[id].$errors[0].$message }}</span>
    </p>
  </div>
</template>
