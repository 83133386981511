import type { UserModule } from '~/types'

// https://github.com/antfu/vite-plugin-pwa#automatic-reload-when-new-content-available
export const install: UserModule = ({ isClient, router }) => {
  if (!isClient)
    return

  router.isReady()
    .then(async () => {
      router.beforeEach(async (to) => {
        // redirect to login page if not logged in and trying to access a restricted page
        const publicPages = ['/login']
        const authRequired = !publicPages.includes(to.path)
        const user = useUserStore()

        // TODO refactor
        if (!user.canNutzer && (to.name === 'nutzer' || to.name === 'detail-nutzer-id'))
          return '/'
        if (!user.canApotheken && (to.name === 'apotheke' || to.name === 'detail-apotheke-id'))
          return '/'
        if (!user.canRst && (to.name === 'rst' || to.name === 'detail-rst-id'))
          return '/'
        if (!user.canPlaene && (to.name === 'plan' || to.name === 'detail-plan-id'))
          return '/'
        if (!user.canAntraege && (to.name === 'aenderungsantrag'))
          return '/'
        if (!user.canEntfernungen && (to.name === 'entfernung'))
          return '/'
        if (!user.canNNF && (to.name === 'nnf' || to.name === 'detail-nnf-id'))
          return '/'
        if (!user.canKarte && (to.name === 'karte'))
          return '/'
        if (!user.canAdmin && (to.name === 'admin'))
          return '/'

        if (authRequired && !user.token)
          user.setRefUrl(to.fullPath)
          // return '/login'
      })
    })
    .catch(() => {})
}
