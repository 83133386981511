import { acceptHMRUpdate, defineStore } from 'pinia'

export const useCommonStore = defineStore('common', () => {
  const loading = ref<boolean>(false)
  const refreshing = ref<boolean>(false)

  function setLoading(isLoading: boolean) {
    loading.value = isLoading
  }

  function setRefreshing(isRefreshing: boolean) {
    refreshing.value = isRefreshing
  }

  return {
    setLoading,
    setRefreshing,
    loading,
    refreshing,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useCommonStore as any, import.meta.hot))
