<script setup lang="ts">
import __MACROS_useVModel from "/vue-macros/define-models/use-vmodel";
defineProps({
  id: null,
  modelValue: null,
  value: null,
  label: null,
  icon: null,
  type: null,
  row: { type: Boolean },
  typename: null,
  values: null
});
defineEmits(["update:id", "update:modelValue", "update:value", "update:label", "update:icon", "update:type", "update:row", "update:typename", "update:values"]);
import * as typeFile from '~/types'

const { modelValue } = __MACROS_useVModel("id", "modelValue", "value", "label", "icon", "type", "row", "typename", "values")

function formatEnum(value: string, type: string): string {
  if (!value || !type)
    return `FEHLER: ${value}`

  const importedType = (typeFile as any)[type]
  const typeValue = importedType[value]
  return typeValue || `FEHLER: ${value}`
}
</script>

<template>
  <div :class="row ? 'flex items-center justify-center space-x-2' : ''">
    <label
      v-if="label"
      :for="id"
      class="sb-input-label"
    >
      {{ label }}
    </label>

    <div v-if="icon" class="flex">
      <span class="inline-flex items-center rounded-l-md pa-2 pl-0 pr-2 pt-1 text-sm text-gray-900 dark:text-gray-400">
        <div class="pointer-events-none h-5 w-5 text-gray-500 dark:text-gray-400" :i="icon" />
      </span>
      <label
        :id="id"
        class="rounded-none pa-2 pl-0 pt-1 sb-output-label"
      >
        {{ modelValue }}
      </label>
    </div>

    <label
      v-else-if="type === 'nutzer' && values"
      :id="id"
      class="rounded-none pa-2 pl-0 pt-1 sb-output-label"
    >
      {{ $filters.formatNutzerFromValues(modelValue, values) }}
    </label>

    <label
      v-else-if="type === 'nutzer' && !values"
      :id="id"
      class="rounded-none pa-2 pl-0 pt-1 sb-output-label"
    >
      {{ $filters.formatNutzer(modelValue) }}
    </label>

    <label
      v-else-if="type === 'textarea'"
      :id="id"
      class="rounded-none pa-2 pl-0 pt-1 sb-output-label"
    >
      <pre>{{ modelValue }}</pre>
    </label>

    <label
      v-else-if="type === 'datetime-local'"
      :id="id"
      class="rounded-none pa-2 pl-0 pt-1 sb-output-label"
    >
      {{ useFilter.formatDatetime(modelValue) }}
    </label>

    <label
      v-else-if="type === 'date'"
      :id="id"
      class="rounded-none pa-2 pl-0 pt-1 sb-output-label"
    >
      {{ useFilter.formatDate(modelValue) }}
    </label>

    <label
      v-else-if="type === 'boolean'"
      :id="id"
      class="rounded-none pa-2 pl-0 pt-1 sb-output-label"
    >
      {{ !!modelValue ? 'Ja' : 'Nein' }}
    </label>

    <label
      v-else-if="type === 'checkbox'"
      :id="id"
      class="rounded-none pa-2 pl-0 pt-1 sb-output-label"
    >
      <input :id="`${id}-checkbox`" type="checkbox" :name="id" :checked="modelValue" disabled>
    </label>

    <label
      v-else-if="typename"
      :id="id"
      class="rounded-none pa-2 pl-0 pt-1 sb-output-label"
    >
      {{ modelValue ? formatEnum(modelValue, typename) : '-' }}
    </label>

    <label
      v-else
      :id="id"
      class="break-words rounded-none pa-2 pl-0 pt-1 sb-output-label"
    >
      {{ modelValue || value }}
    </label>
  </div>
</template>
