<script lang="ts" setup>
defineProps({
  label: String,
  route: String,
  icon: String,
})

const router = useRouter()

const activeRoute = computed(() => {
  const matchedRoutes = router.currentRoute.value.matched
  const lastRoute = matchedRoutes[matchedRoutes.length - 1]
  return lastRoute.name
})
const targetRoute = computed(() => {
  return router.resolve({ name: __props.route })
})
</script>

<template>
  <router-link
    class="group flex items-center rounded-lg p-2 text-base text-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-700"
    :class="activeRoute === route || activeRoute && route && activeRoute?.toString().startsWith(`detail-${route}-id`) ? 'bg-gray-200 dark:bg-gray-700' : ''"
    :to="targetRoute ? targetRoute.fullPath : '/'"
  >
    <div
      :i="icon"
      class="h-6 w-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
      fill="currentColor"
      viewBox="0 0 20 20"
      aria-hidden="true"
    />
    <span class="ml-3" sidebar-toggle-item>{{ label }}</span>
  </router-link>
</template>
