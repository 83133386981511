

import __layout_0 from '/app/src/layouts/default.vue'
export const layouts = {
'404': () => import('/app/src/layouts/404.vue'),
'default': __layout_0,
}
export const createGetRoutes = (router, withLayout = false) => {
  const routes = router.getRoutes()
  if (withLayout) {
      return routes
  }
  return () => routes.filter(route => !route.meta.isLayout)
}

export function setupLayouts(routes) {
  function deepSetupLayout(routes, top = true) {
    return routes.map(route => {
      if (route.children?.length > 0) {
        route.children = deepSetupLayout(route.children, false)
      }

      if (!route.component) return route
      
      if (top && route.meta?.layout !== false) {
        return { 
          path: route.path,
          component: layouts[route.meta?.layout || 'default'],
          children: route.path === '/' ? [route] : [{...route, path: ''}],
          meta: {
            isLayout: true
          }
        }
      }

      if (route.meta?.layout) {
        return { 
          path: route.path,
          component: layouts[route.meta?.layout],
          children: [ {...route, path: ''} ],
          meta: {
            isLayout: true
          }
        }
      }

      return route
    })
  }

    return deepSetupLayout(routes)

}
